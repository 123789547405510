import { ArrowDown, ArrowUp } from 'lucide-react'
import { useMemo } from 'react'

export const PercentIncrease = ({
	prev,
	current,
}: {
	prev?: number
	current?: number
}) => {
	if (!prev || !current) return null

	const getPercentIncrease = (prev: number, current: number) =>
		((current - prev) / prev) * 100

	const value = useMemo(
		() => getPercentIncrease(prev, current),
		[prev, current],
	)

	if (value === undefined) return null

	return (
		<div className='flex flex-row items-end gap-1 text-slate-500 text-sm leading-none'>
			{value > 0 && <ArrowUp className='size-4 text-green-500' />}
			{value < 0 && <ArrowDown className='size-4 text-red-500' />}
			{Math.abs(value).toFixed(2)}%
		</div>
	)
}
